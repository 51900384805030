import React, { Fragment } from 'react';
import '../../map/map-button.css';
import { dataFields } from '../../config/data-fields-config';
import { MultiDataEntry } from '../data-components/multi-data-entry/multi-data-entry';
import { DataEntryGroup } from '../data-components/data-entry-group';
import { DynamicsBuildingPane, DynamicsDataEntry } from './dynamics/dynamics-data-entry';
import { FieldRow } from '../data-components/field-row';
import { Link } from 'react-router-dom';
import { Category } from '../../config/categories-config';
import NumericDataEntry from '../data-components/numeric-data-entry';
import SelectDataEntry from '../data-components/select-data-entry';
import Verification from '../data-components/verification';
import YearDataEntry from '../data-components/year-data-entry';
import withCopyEdit from '../data-container';
import DataEntry from '../data-components/data-entry';
import InfoBox from '../../components/info-box';
import { LogicalDataEntry } from '../data-components/logical-data-entry/logical-data-entry';
import { useDisplayPreferences } from '../../displayPreferences-context';
const SurvivalStatusOptions = [
    'Same as Historical Map (Unchanged)',
    'Similar to Historical Map (Some Changes)',
    'Historical Building(s) Demolished',
    'Current Building on Previous Green Space'
];
/**
* Age view/edit section
*/
const AgeView = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const currentYear = new Date().getFullYear();
    const building = props.building;
    const thisYear = (new Date()).getFullYear();
    const currentBuildingConstructionYear = building.date_year || undefined;
    const ageLinkUrl = `/${props.mode}/${Category.Age}/${props.building.building_id}`;
    const { historicData, historicDataSwitchOnClick, darkLightTheme } = useDisplayPreferences();
    const switchToSurvivalMapStyle = (e) => {
        e.preventDefault();
        if (props.mapColourScale == "survival_status") {
            props.onMapColourScale('date_year');
            historicDataSwitchOnClick(e);
        }
        else {
            props.onMapColourScale('survival_status');
            historicDataSwitchOnClick(e);
        }
    };
    if (props.building.date_source == "Expert knowledge of building" ||
        props.building.date_source == "Expert estimate from image" ||
        props.building.date_source == null) {
        return (React.createElement(Fragment, null,
            React.createElement(DataEntryGroup, { name: "Building age" },
                React.createElement(YearDataEntry, { year: props.building.date_year, upper: props.building.date_upper, lower: props.building.date_lower, mode: props.mode, copy: props.copy, onChange: props.onChange, allow_verify: props.user !== undefined && props.building.date_year !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("date_year"), user_verified_as: props.user_verified.date_year, verified_count: props.building.verified.date_year, allow_verify_upper: props.user !== undefined && props.building.date_upper !== null && !props.edited, onVerify_upper: props.onVerify, user_verified_upper: props.user_verified.hasOwnProperty("date_upper"), user_verified_as_upper: props.user_verified.date_upper, verified_count_upper: props.building.verified.date_upper, allow_verify_lower: props.user !== undefined && props.building.date_lower !== null && !props.edited, onVerify_lower: props.onVerify, user_verified_lower: props.user_verified.hasOwnProperty("date_lower"), user_verified_as_lower: props.user_verified.date_lower, verified_count_lower: props.building.verified.date_lower }),
                React.createElement(NumericDataEntry, { title: dataFields.facade_year.title, slug: "facade_year", value: props.building.facade_year, mode: props.mode, copy: props.copy, onChange: props.onChange, step: 1, min: 1, max: currentYear, tooltip: dataFields.facade_year.tooltip }),
                React.createElement(Verification, { slug: "facade_year", allow_verify: props.user !== undefined && props.building.facade_year !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("facade_year"), user_verified_as: props.user_verified.facade_year, verified_count: props.building.verified.facade_year }),
                React.createElement(SelectDataEntry, { title: dataFields.date_source.title, slug: "date_source", value: props.building.date_source, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.date_source.tooltip, placeholder: dataFields.date_source.example, options: dataFields.date_source.items }),
                (props.building.date_source == dataFields.date_source.items[0] ||
                    props.building.date_source == dataFields.date_source.items[1] ||
                    props.building.date_source == null) ? React.createElement(React.Fragment, null) :
                    React.createElement(React.Fragment, null,
                        React.createElement(MultiDataEntry, { title: dataFields.date_link.title, slug: "date_link", value: props.building.date_link, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.date_link.tooltip, placeholder: "https://...", editableEntries: true, isUrl: true }))),
            React.createElement(DataEntryGroup, { name: "Cladding, extensions and retrofits" },
                React.createElement(NumericDataEntry, { slug: 'age_cladding_date', title: dataFields.age_cladding_date.title, value: props.building.age_cladding_date, mode: props.mode, copy: props.copy, onChange: props.onChange, step: 1, min: 1, max: currentYear, tooltip: dataFields.extension_year.tooltip }),
                React.createElement(Verification, { slug: "age_cladding_date", allow_verify: props.user !== undefined && props.building.age_cladding_date !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("age_cladding_date"), user_verified_as: props.user_verified.age_cladding_date, verified_count: props.building.verified.age_cladding_date }),
                React.createElement(SelectDataEntry, { title: dataFields.age_cladding_date_source_type.title, slug: "age_cladding_date_source_type", value: props.building.age_cladding_date_source_type, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.age_cladding_date_source_type.tooltip, options: dataFields.age_cladding_date_source_type.items, placeholder: dataFields.age_cladding_date_source_type.example }),
                (props.building.age_cladding_date_source_type == dataFields.age_cladding_date_source_type.items[0] ||
                    props.building.age_cladding_date_source_type == dataFields.age_cladding_date_source_type.items[1] ||
                    props.building.age_cladding_date_source_type == null) ? React.createElement(React.Fragment, null) :
                    React.createElement(React.Fragment, null,
                        React.createElement(MultiDataEntry, { title: dataFields.age_cladding_date_source_links.title, slug: "age_cladding_date_source_links", value: props.building.age_cladding_date_source_links, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.age_cladding_date_source_links.tooltip, placeholder: "https://...", editableEntries: true, isUrl: true })),
                React.createElement("hr", null),
                React.createElement(NumericDataEntry, { slug: 'age_extension_date', title: dataFields.age_extension_date.title, value: props.building.age_extension_date, mode: props.mode, copy: props.copy, onChange: props.onChange, step: 1, min: 1, max: currentYear, tooltip: dataFields.extension_year.tooltip }),
                React.createElement(Verification, { slug: "age_extension_date", allow_verify: props.user !== undefined && props.building.age_extension_date !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("age_extension_date"), user_verified_as: props.user_verified.age_extension_date, verified_count: props.building.verified.age_extension_date }),
                React.createElement(SelectDataEntry, { title: dataFields.age_extension_date_source_type.title, slug: "age_extension_date_source_type", value: props.building.age_extension_date_source_type, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.age_extension_date_source_type.tooltip, options: dataFields.age_extension_date_source_type.items, placeholder: dataFields.age_extension_date_source_type.example }),
                (props.building.age_extension_date_source_type == dataFields.age_extension_date_source_type.items[0] ||
                    props.building.age_extension_date_source_type == dataFields.age_extension_date_source_type.items[1] ||
                    props.building.age_extension_date_source_type == null) ? React.createElement(React.Fragment, null) :
                    React.createElement(React.Fragment, null,
                        React.createElement(MultiDataEntry, { title: dataFields.age_extension_date_source_links.title, slug: "age_extension_date_source_links", value: props.building.age_extension_date_source_links, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.age_extension_date_source_links.tooltip, placeholder: "https://...", editableEntries: true, isUrl: true })),
                React.createElement("hr", null),
                React.createElement(NumericDataEntry, { slug: 'age_retrofit_date', title: dataFields.age_retrofit_date.title, value: props.building.age_retrofit_date, mode: props.mode, copy: props.copy, onChange: props.onChange, step: 1, min: 1, max: currentYear, tooltip: dataFields.extension_year.tooltip }),
                React.createElement(Verification, { slug: "age_retrofit_date", allow_verify: props.user !== undefined && props.building.age_retrofit_date !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("age_retrofit_date"), user_verified_as: props.user_verified.age_retrofit_date, verified_count: props.building.verified.age_retrofit_date }),
                React.createElement(SelectDataEntry, { title: dataFields.age_retrofit_date_source_type.title, slug: "age_retrofit_date_source_type", value: props.building.age_retrofit_date_source_type, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.age_retrofit_date_source_type.tooltip, options: dataFields.age_retrofit_date_source_type.items, placeholder: dataFields.age_retrofit_date_source_type.example }),
                (props.building.age_retrofit_date_source_type == dataFields.age_retrofit_date_source_type.items[0] ||
                    props.building.age_retrofit_date_source_type == dataFields.age_retrofit_date_source_type.items[1] ||
                    props.building.age_retrofit_date_source_type == null) ? React.createElement(React.Fragment, null) :
                    React.createElement(React.Fragment, null,
                        React.createElement(MultiDataEntry, { title: dataFields.age_retrofit_date_source_links.title, slug: "age_retrofit_date_source_links", value: props.building.age_retrofit_date_source_links, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.age_retrofit_date_source_links.tooltip, placeholder: "https://...", editableEntries: true, isUrl: true }))),
            React.createElement(DataEntryGroup, { name: "Lifespan and site history" },
                React.createElement("button", { className: `map-switcher-inline ${props.mapColourScale == "survival_status" ? "enabled-state" : "disabled-state"} btn btn-outline btn-outline-dark ${darkLightTheme}`, onClick: switchToSurvivalMapStyle }, (props.mapColourScale == "is_domestic") ? 'Click here to hide historical maps' : 'Click here to show historical maps'),
                React.createElement(DataEntryGroup, { name: "Constructions and demolitions on this site", showCount: false },
                    React.createElement(DynamicsBuildingPane, null,
                        React.createElement("label", null,
                            "Current building (age data ",
                            React.createElement(Link, { to: ageLinkUrl }, "editable here"),
                            ")"),
                        React.createElement(FieldRow, null,
                            React.createElement("div", null,
                                React.createElement(NumericDataEntry, { slug: '', title: dataFields.demolished_buildings.items.year_constructed.title, value: currentBuildingConstructionYear, disabled: true, mode: 'view' })),
                            React.createElement("div", null,
                                React.createElement(NumericDataEntry, { slug: '', title: dataFields.demolished_buildings.items.year_demolished.title, value: undefined, placeholder: '---', disabled: true, mode: 'view' })),
                            React.createElement("div", { style: { flex: '0 1 27%' } },
                                React.createElement(DataEntry, { slug: '', title: 'Lifespan to date', value: (thisYear - currentBuildingConstructionYear) + '', disabled: true, mode: 'view' })))),
                    currentBuildingConstructionYear == undefined ?
                        React.createElement(InfoBox, null,
                            "To add historical records, fill in the ",
                            React.createElement(Link, { to: ageLinkUrl }, "Age"),
                            " data first.") :
                        React.createElement(React.Fragment, null,
                            React.createElement(LogicalDataEntry, { slug: 'dynamics_has_demolished_buildings', title: dataFields.dynamics_has_demolished_buildings.title, value: building.dynamics_has_demolished_buildings, disallowFalse: ((_b = (_a = building.demolished_buildings) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0) > 0, disallowNull: ((_d = (_c = building.demolished_buildings) === null || _c === void 0 ? void 0 : _c.length) !== null && _d !== void 0 ? _d : 0) > 0, onChange: props.onSaveChange, mode: props.mode, copy: props.copy }),
                            building.dynamics_has_demolished_buildings &&
                                React.createElement(React.Fragment, null,
                                    React.createElement(DynamicsDataEntry
                                    /*
                                        Will clear the edits and new record data upon navigating to another building.
                                        Should get a better way to do this, plus a way to actually keep unsaved edits.
                                    */
                                    , { 
                                        /*
                                            Will clear the edits and new record data upon navigating to another building.
                                            Should get a better way to do this, plus a way to actually keep unsaved edits.
                                        */
                                        key: building.building_id, value: building.demolished_buildings, editableEntries: true, slug: 'demolished_buildings', title: dataFields.demolished_buildings.title, mode: props.mode, onChange: props.onChange, onSaveAdd: props.onSaveAdd, hasEdits: props.edited, maxYear: currentBuildingConstructionYear, minYear: 50 }),
                                    props.mode === 'view' &&
                                        React.createElement(InfoBox, null, "Switch to edit mode to add/edit past building records")))),
                React.createElement(InfoBox, { type: 'warning' },
                    "This section is under development in collaboration with the historic environment sector. Please let us know your suggestions on the ",
                    React.createElement("a", { href: "https://discuss.colouring.london/t/dynamics-category-discussion/107" }, "discussion forum"),
                    "! (external link - save your edits first)"))));
    }
    ;
    return (React.createElement(Fragment, null,
        React.createElement(DataEntryGroup, { name: "Building age" },
            React.createElement(YearDataEntry, { year: props.building.date_year, upper: props.building.date_upper, lower: props.building.date_lower, mode: props.mode, copy: props.copy, onChange: props.onChange, allow_verify: props.user !== undefined && props.building.date_year !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("date_year"), user_verified_as: props.user_verified.date_year, verified_count: props.building.verified.date_year, allow_verify_upper: props.user !== undefined && props.building.date_upper !== null && !props.edited, onVerify_upper: props.onVerify, user_verified_upper: props.user_verified.hasOwnProperty("date_upper"), user_verified_as_upper: props.user_verified.date_upper, verified_count_upper: props.building.verified.date_upper, allow_verify_lower: props.user !== undefined && props.building.date_lower !== null && !props.edited, onVerify_lower: props.onVerify, user_verified_lower: props.user_verified.hasOwnProperty("date_lower"), user_verified_as_lower: props.user_verified.date_lower, verified_count_lower: props.building.verified.date_lower }),
            React.createElement(NumericDataEntry, { title: dataFields.facade_year.title, slug: "facade_year", value: props.building.facade_year, mode: props.mode, copy: props.copy, onChange: props.onChange, step: 1, min: 1, max: currentYear, tooltip: dataFields.facade_year.tooltip }),
            React.createElement(Verification, { slug: "facade_year", allow_verify: props.user !== undefined && props.building.facade_year !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("facade_year"), user_verified_as: props.user_verified.facade_year, verified_count: props.building.verified.facade_year }),
            React.createElement(SelectDataEntry, { title: dataFields.date_source.title, slug: "date_source", value: props.building.date_source, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.date_source.tooltip, options: dataFields.date_source.items, placeholder: dataFields.date_source.example }),
            (props.building.date_source == dataFields.date_source.items[0] ||
                props.building.date_source == dataFields.date_source.items[1] ||
                props.building.date_source == null) ? React.createElement(React.Fragment, null) :
                React.createElement(React.Fragment, null,
                    React.createElement(MultiDataEntry, { title: dataFields.date_link.title, slug: "date_link", value: props.building.date_link, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.date_link.tooltip, placeholder: "https://...", editableEntries: true, isUrl: true }))),
        React.createElement(DataEntryGroup, { name: "Cladding, extensions and retrofits" },
            React.createElement(NumericDataEntry, { slug: 'age_cladding_date', title: dataFields.age_cladding_date.title, value: props.building.age_cladding_date, mode: props.mode, copy: props.copy, onChange: props.onChange, step: 1, min: 1, max: currentYear, tooltip: dataFields.extension_year.tooltip }),
            React.createElement(Verification, { slug: "age_cladding_date", allow_verify: props.user !== undefined && props.building.age_cladding_date !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("age_cladding_date"), user_verified_as: props.user_verified.age_cladding_date, verified_count: props.building.verified.age_cladding_date }),
            React.createElement(SelectDataEntry, { title: dataFields.age_cladding_date_source_type.title, slug: "age_cladding_date_source_type", value: props.building.age_cladding_date_source_type, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.age_cladding_date_source_type.tooltip, options: dataFields.age_cladding_date_source_type.items, placeholder: dataFields.age_cladding_date_source_type.example }),
            (props.building.age_cladding_date_source_type == dataFields.age_cladding_date_source_type.items[0] ||
                props.building.age_cladding_date_source_type == dataFields.age_cladding_date_source_type.items[1] ||
                props.building.age_cladding_date_source_type == null) ? React.createElement(React.Fragment, null) :
                React.createElement(React.Fragment, null,
                    React.createElement(MultiDataEntry, { title: dataFields.age_cladding_date_source_links.title, slug: "age_cladding_date_source_links", value: props.building.age_cladding_date_source_links, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.age_cladding_date_source_links.tooltip, placeholder: "https://...", editableEntries: true, isUrl: true })),
            React.createElement("hr", null),
            React.createElement(NumericDataEntry, { slug: 'age_extension_date', title: dataFields.age_extension_date.title, value: props.building.age_extension_date, mode: props.mode, copy: props.copy, onChange: props.onChange, step: 1, min: 1, max: currentYear, tooltip: dataFields.extension_year.tooltip }),
            React.createElement(Verification, { slug: "age_extension_date", allow_verify: props.user !== undefined && props.building.age_extension_date !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("age_extension_date"), user_verified_as: props.user_verified.age_extension_date, verified_count: props.building.verified.age_extension_date }),
            React.createElement(SelectDataEntry, { title: dataFields.age_extension_date_source_type.title, slug: "age_extension_date_source_type", value: props.building.age_extension_date_source_type, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.age_extension_date_source_type.tooltip, options: dataFields.age_extension_date_source_type.items, placeholder: dataFields.age_extension_date_source_type.example }),
            (props.building.age_extension_date_source_type == dataFields.age_extension_date_source_type.items[0] ||
                props.building.age_extension_date_source_type == dataFields.age_extension_date_source_type.items[1] ||
                props.building.age_extension_date_source_type == null) ? React.createElement(React.Fragment, null) :
                React.createElement(React.Fragment, null,
                    React.createElement(MultiDataEntry, { title: dataFields.age_extension_date_source_links.title, slug: "age_extension_date_source_links", value: props.building.age_extension_date_source_links, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.age_extension_date_source_links.tooltip, placeholder: "https://...", editableEntries: true, isUrl: true })),
            React.createElement("hr", null),
            React.createElement(NumericDataEntry, { slug: 'age_retrofit_date', title: dataFields.age_retrofit_date.title, value: props.building.age_retrofit_date, mode: props.mode, copy: props.copy, onChange: props.onChange, step: 1, min: 1, max: currentYear, tooltip: dataFields.extension_year.tooltip }),
            React.createElement(Verification, { slug: "age_retrofit_date", allow_verify: props.user !== undefined && props.building.age_retrofit_date !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("age_retrofit_date"), user_verified_as: props.user_verified.age_retrofit_date, verified_count: props.building.verified.age_retrofit_date }),
            React.createElement(SelectDataEntry, { title: dataFields.age_retrofit_date_source_type.title, slug: "age_retrofit_date_source_type", value: props.building.age_retrofit_date_source_type, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.age_retrofit_date_source_type.tooltip, options: dataFields.age_retrofit_date_source_type.items, placeholder: dataFields.age_retrofit_date_source_type.example }),
            (props.building.age_retrofit_date_source_type == dataFields.age_retrofit_date_source_type.items[0] ||
                props.building.age_retrofit_date_source_type == dataFields.age_retrofit_date_source_type.items[1] ||
                props.building.age_retrofit_date_source_type == null) ? React.createElement(React.Fragment, null) :
                React.createElement(React.Fragment, null,
                    React.createElement(MultiDataEntry, { title: dataFields.age_retrofit_date_source_links.title, slug: "age_retrofit_date_source_links", value: props.building.age_retrofit_date_source_links, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.age_retrofit_date_source_links.tooltip, placeholder: "https://...", editableEntries: true, isUrl: true }))),
        React.createElement(DataEntryGroup, { name: "Lifespan and site history" },
            React.createElement("button", { className: `map-switcher-inline ${props.mapColourScale == "survival_status" ? "enabled-state" : "disabled-state"} btn btn-outline btn-outline-dark ${darkLightTheme}`, onClick: switchToSurvivalMapStyle }, (props.mapColourScale == "is_domestic") ? 'Click here to hide historical maps' : 'Click here to show historical maps'),
            React.createElement(DataEntryGroup, { name: "Constructions and demolitions on this site", showCount: false },
                React.createElement(DynamicsBuildingPane, null,
                    React.createElement("label", null,
                        "Current building (age data ",
                        React.createElement(Link, { to: ageLinkUrl }, "editable here"),
                        ")"),
                    React.createElement(FieldRow, null,
                        React.createElement("div", null,
                            React.createElement(NumericDataEntry, { slug: '', title: dataFields.demolished_buildings.items.year_constructed.title, value: currentBuildingConstructionYear, disabled: true, mode: 'view' })),
                        React.createElement("div", null,
                            React.createElement(NumericDataEntry, { slug: '', title: dataFields.demolished_buildings.items.year_demolished.title, value: undefined, placeholder: '---', disabled: true, mode: 'view' })),
                        React.createElement("div", { style: { flex: '0 1 27%' } },
                            React.createElement(DataEntry, { slug: '', title: 'Lifespan to date', value: (thisYear - currentBuildingConstructionYear) + '', disabled: true, mode: 'view' })))),
                currentBuildingConstructionYear == undefined ?
                    React.createElement(InfoBox, null,
                        "To add historical records, fill in the ",
                        React.createElement(Link, { to: ageLinkUrl }, "Age"),
                        " data first.") :
                    React.createElement(React.Fragment, null,
                        React.createElement(LogicalDataEntry, { slug: 'dynamics_has_demolished_buildings', title: dataFields.dynamics_has_demolished_buildings.title, value: building.dynamics_has_demolished_buildings, disallowFalse: ((_f = (_e = building.demolished_buildings) === null || _e === void 0 ? void 0 : _e.length) !== null && _f !== void 0 ? _f : 0) > 0, disallowNull: ((_h = (_g = building.demolished_buildings) === null || _g === void 0 ? void 0 : _g.length) !== null && _h !== void 0 ? _h : 0) > 0, onChange: props.onSaveChange, mode: props.mode, copy: props.copy }),
                        building.dynamics_has_demolished_buildings &&
                            React.createElement(React.Fragment, null,
                                React.createElement(DynamicsDataEntry
                                /*
                                    Will clear the edits and new record data upon navigating to another building.
                                    Should get a better way to do this, plus a way to actually keep unsaved edits.
                                */
                                , { 
                                    /*
                                        Will clear the edits and new record data upon navigating to another building.
                                        Should get a better way to do this, plus a way to actually keep unsaved edits.
                                    */
                                    key: building.building_id, value: building.demolished_buildings, editableEntries: true, slug: 'demolished_buildings', title: dataFields.demolished_buildings.title, mode: props.mode, onChange: props.onChange, onSaveAdd: props.onSaveAdd, hasEdits: props.edited, maxYear: currentBuildingConstructionYear, minYear: 50 }),
                                props.mode === 'view' &&
                                    React.createElement(InfoBox, null, "Switch to edit mode to add/edit past building records")))),
            React.createElement(InfoBox, { type: 'warning' },
                "This section is under development in collaboration with the historic environment sector. Please let us know your suggestions on the ",
                React.createElement("a", { href: "https://discuss.colouring.london/t/dynamics-category-discussion/107" }, "discussion forum"),
                "! (external link - save your edits first)"))));
};
const AgeContainer = withCopyEdit(AgeView);
export default AgeContainer;
