import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './header.css';
import { Logo } from './components/logo';
import { WithSeparator } from './components/with-separator';
import { useAuth } from './auth-context';
let config = require('../cc-config.json');
/*
function getCurrentActiveCityLinks(username: string): MenuLink[][] {
    return [
        [
            {
                to: "/view/categories",
                text: "View Maps"
            },
            {
                to: "/edit/categories",
                text: "Edit Maps"
            },
            {
                to: "/data-extracts.html",
                text: "Download data"
            },
            {
                to: "https://github.com/colouring-cities/manual/wiki",
                text: "Open Manual - Wiki",
                external: true
            },
            {
                to: config.githubURL,
                text: "Open code",
                external: true
            },
            {
                to: "https://github.com/colouring-cities/manual/wiki",
                text: "Colouring Cities Open Manual/Wiki",
                disabled: false,
                external: true
            },
            {
                to: "/showcase.html",
                text: "Case Study Showcase",
                disabled: true,
            },
        ],
    ];
}
*/
function getCurrentMenuLinks(username) {
    return [
        [
            ...(username != undefined ?
                [
                    {
                        to: "/my-account.html",
                        text: `Account (${username})`
                    }
                ] :
                [
                    {
                        to: "/login.html",
                        text: "Log in"
                    },
                    {
                        to: "/sign-up.html",
                        text: "Sign up"
                    }
                ])
        ],
        [
            {
                to: "/view/categories",
                text: "View Maps"
            },
            {
                to: "/edit/categories",
                text: "Edit Maps"
            },
            {
                to: "/data-extracts.html",
                text: "Download data"
            },
            {
                to: "https://github.com/colouring-cities/manual/wiki",
                text: "Open Manual - Wiki",
                external: true
            },
            {
                to: config.githubURL,
                text: "Open code",
                external: true
            },
            {
                to: "https://github.com/colouring-cities/manual/wiki",
                text: "Colouring Cities Open Manual/Wiki",
                disabled: false,
                external: true
            },
            {
                to: "/showcase.html",
                text: "Case Study Showcase",
                disabled: true,
            },
        ],
        [
            {
                to: "https://www.ahdap.org/colouring-australia",
                text: "About",
                external: true
            },
            {
                to: "https://www.ahdap.org/colouring-australia#data_categories",
                text: "Data Categories",
                external: true
            },
            {
                to: "https://www.ahdap.org/colouring-australia#who_are_we",
                text: "Who's Involved?",
                external: true
            },
        ],
        [
            {
                to: "/leaderboard.html",
                text: "Top Contributors"
            },
        ],
        [
            {
                to: "/data-ethics.html",
                text: "Data Ethics",
                external: true
            },
            {
                to: "/privacy-policy.html",
                text: "Privacy Policy"
            },
            {
                to: "/contributor-agreement.html",
                text: "Contributor Agreement"
            },
            {
                to: "/code-of-conduct.html",
                text: "Code of Conduct"
            },
            {
                to: "/data-accuracy.html",
                text: "Data Accuracy Agreement"
            },
        ],
        [
            {
                to: "/contact.html",
                text: "Contact"
            },
        ],
    ];
}
const ActiveCityMenu = ({ onNavigate }) => {
    return (React.createElement(WithSeparator, { separator: React.createElement("hr", null) },
        React.createElement("ul", { key: `menu-section`, className: "navbar-nav flex-container" }, Object.keys(config.cityLinkMap).map(item => React.createElement("li", { className: 'nav-item', key: `${item}` }, item == config.cityName ?
            React.createElement(LinkStub, { note: "" }, item) :
            React.createElement(ExternalNavLink, { to: config.cityLinkMap[item] }, item))))));
};
const Menu = ({ onNavigate }) => {
    const { user } = useAuth();
    const menuLinkSections = getCurrentMenuLinks(user === null || user === void 0 ? void 0 : user.username);
    return (React.createElement(WithSeparator, { separator: React.createElement("hr", null) }, menuLinkSections.map((section, idx) => React.createElement("ul", { key: `menu-section-${idx}`, className: "navbar-nav flex-container" }, section.map(item => (React.createElement("li", { className: 'nav-item', key: `${item.to}-${item.text}` }, item.disabled ?
        React.createElement(LinkStub, { note: item.note }, item.text) :
        item.external ?
            React.createElement(ExternalNavLink, { to: item.to }, item.text) :
            React.createElement(InternalNavLink, { to: item.to, onClick: onNavigate }, item.text))))))));
};
const InternalNavLink = ({ to, onClick, children }) => (React.createElement(NavLink, { className: "nav-link", to: to, onClick: onClick }, children));
const ExternalNavLink = ({ to, children }) => (React.createElement("a", { className: "nav-link", href: to }, children));
const LinkStub = ({ note, children }) => (React.createElement("a", { className: "nav-link disabled" },
    children,
    React.createElement("span", { className: "link-note" }, note)));
export const Header = ({ animateLogo }) => {
    const [openMenuName, setOpenMenuName] = useState("");
    const handleNavigate = () => setOpenMenuName("");
    return (React.createElement("header", { className: "main-header navbar navbar-light" },
        React.createElement("div", { className: "nav-header" },
            React.createElement(NavLink, { to: "/" },
                React.createElement(Logo, { variant: animateLogo ? 'animated' : 'default' })),
            React.createElement("button", { className: "navbar-toggler", type: "button", onClick: () => setOpenMenuName(openMenuName == "activeCity" ? "" : "activeCity"), "aria-expanded": (openMenuName == "activeCity"), "aria-label": "Toggle cityselector" },
                config.cityName,
                "\u00A0",
                !(openMenuName == "activeCity") ?
                    React.createElement("span", { className: "navbar-toggler-icon" })
                    : React.createElement("span", { className: "close" }, "\u00D7")),
            React.createElement("button", { className: "navbar-toggler", type: "button", onClick: () => setOpenMenuName(openMenuName == "menu" ? "" : "menu"), "aria-expanded": (openMenuName == "menu"), "aria-label": "Toggle cityselector" },
                "Menu\u00A0",
                !(openMenuName == "menu") ?
                    React.createElement("span", { className: "navbar-toggler-icon" })
                    : React.createElement("span", { className: "close" }, "\u00D7"))),
        React.createElement("nav", { className: (openMenuName != "activeCity") ? 'collapse navbar-collapse' : 'navbar-collapse' },
            React.createElement(ActiveCityMenu, { onNavigate: handleNavigate })),
        React.createElement("nav", { className: (openMenuName != "menu") ? 'collapse navbar-collapse' : 'navbar-collapse' },
            React.createElement(Menu, { onNavigate: handleNavigate }))));
};
