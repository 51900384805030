var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useCallback, useMemo, useState } from 'react';
import './multi-data-entry.css';
import { DataEntryInput } from '../data-entry-input';
import { DataTitleCopyable } from '../data-title';
import { CloseIcon, SaveIcon } from '../../../components/icons';
export const MultiDataEntry = (_a) => {
    var _b;
    var { editableEntries = false, copyable = true, confirmOnEnter = true } = _a, props = __rest(_a, ["editableEntries", "copyable", "confirmOnEnter"]);
    const [newValue, setNewValue] = useState();
    const values = useMemo(() => { var _a; return (_a = props.value) !== null && _a !== void 0 ? _a : []; }, [props.value]);
    const edit = useCallback((index, value) => {
        const editedValues = values.slice();
        editedValues.splice(index, 1, value);
        props.onChange(props.slug, editedValues);
    }, [values, props.onChange, props.slug]);
    /* accept a newValue parameter to handle cases where the value is set and submitted at the same time
     * (like with autofill select enabled) - but otherwise use the current newValue saved in state
     */
    const addNew = useCallback((newValueArg) => {
        const val = newValueArg !== null && newValueArg !== void 0 ? newValueArg : newValue;
        if (val == undefined)
            return;
        const editedValues = values.slice().concat(val);
        setNewValue(null);
        props.onChange(props.slug, editedValues);
    }, [newValue, values, props.onChange, props.slug]);
    const clearNew = useCallback(() => {
        setNewValue(null);
    }, []);
    const remove = useCallback((index) => {
        const editedValues = values.slice();
        editedValues.splice(index, 1);
        props.onChange(props.slug, editedValues);
    }, [values, props.onChange, props.slug]);
    const isEditing = props.mode === 'edit';
    const isDisabled = !isEditing || props.disabled;
    const slugWithModifier = props.slug + ((_b = props.slugModifier) !== null && _b !== void 0 ? _b : '');
    return (React.createElement(React.Fragment, null,
        React.createElement(DataTitleCopyable, { slug: props.slug, slugModifier: props.slugModifier, title: props.title, tooltip: props.tooltip, disabled: props.disabled || props.value == undefined || props.value.length === 0, copy: copyable ? props.copy : undefined }),
        React.createElement("div", { id: `${props.slug}-wrapper` },
            values.length === 0 && !isEditing &&
                React.createElement("div", { className: "input-group" },
                    React.createElement("input", { className: "form-control no-entries", type: "text", value: "No entries", disabled: true })),
            React.createElement("ul", { className: "data-entry-list" },
                values.map((val, i) => (React.createElement("li", { className: "input-group", key: i /* i as key prevents input component recreation on edit */ },
                    React.createElement(DataEntryInput, { slug: props.slug, name: `${slugWithModifier}-${i}`, id: `${slugWithModifier}-${i}`, value: val, disabled: !editableEntries || isDisabled, onChange: (_key, val) => edit(i, val), maxLength: props.maxLength, isUrl: props.isUrl, valueTransform: props.valueTransform, autofill: props.autofill, showAllOptionsOnEmpty: props.showAllOptionsOnEmpty }),
                    !isDisabled &&
                        React.createElement("div", { className: "input-group-append" },
                            React.createElement("button", { type: "button", onClick: () => remove(i), title: "Remove", "data-index": i, className: "btn btn-outline-dark data-entry-list-button" },
                                React.createElement(CloseIcon, null)))))),
                !isDisabled &&
                    React.createElement("li", { className: "input-group" },
                        React.createElement(DataEntryInput, { slug: props.slug, name: slugWithModifier, id: slugWithModifier, value: newValue, disabled: props.disabled, required: props.required && values.length < 1, onChange: (_key, val) => setNewValue(val), onConfirm: (_key, val) => addNew(val), maxLength: props.maxLength, placeholder: props.placeholder, isUrl: props.isUrl, valueTransform: props.valueTransform, confirmOnEnter: confirmOnEnter, autofill: props.autofill, showAllOptionsOnEmpty: props.showAllOptionsOnEmpty, confirmOnAutofillSelect: true }),
                        newValue != undefined &&
                            React.createElement(React.Fragment, null,
                                React.createElement("div", { className: "input-group-append" },
                                    React.createElement("button", { type: "button", className: "btn btn-primary data-entry-list-button", title: "Confirm new value", onClick: () => addNew() },
                                        React.createElement(SaveIcon, null))),
                                React.createElement("div", { className: "input-group-append" },
                                    React.createElement("button", { type: "button", onClick: () => clearNew(), title: "Clear new value", className: "btn btn-warning data-entry-list-button" },
                                        React.createElement(CloseIcon, null)))))))));
};
