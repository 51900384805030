import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import Confetti from 'canvas-confetti';
import _ from 'lodash';
import { apiPost } from '../apiHelpers';
import { sendBuildingUpdate } from '../api-data/building-update';
import ErrorBox from '../components/error-box';
import InfoBox from '../components/info-box';
import { compareObjects } from '../helpers';
import ContainerHeader from './container-header';
import { CopyControl } from './header-buttons/copy-control';
import { ViewEditControl } from './header-buttons/view-edit-control';
import './data-container.css';
import { dataFields } from '../config/data-fields-config';
import { EditHistoryLatest } from './edit-history/edit-history-latest';
/**
 * Shared functionality for view/edit forms
 *
 * See React Higher-order-component docs for the pattern
 * - https://reactjs.org/docs/higher-order-components.html
 *
 * @param WrappedComponent
 */
const withCopyEdit = (WrappedComponent) => {
    return class DataContainer extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                error: undefined,
                copying: false,
                keys_to_copy: {},
                buildingEdits: {},
                currentBuildingId: undefined,
                currentBuildingRevisionId: undefined,
                mapColourScale: undefined,
                onMapColourScale: undefined
            };
            this.handleChange = this.handleChange.bind(this);
            this.handleReset = this.handleReset.bind(this);
            this.handleSubmit = this.handleSubmit.bind(this);
            this.handleVerify = this.handleVerify.bind(this);
            this.handleSaveAdd = this.handleSaveAdd.bind(this);
            this.handleSaveChange = this.handleSaveChange.bind(this);
            this.toggleCopying = this.toggleCopying.bind(this);
            this.toggleCopyAttribute = this.toggleCopyAttribute.bind(this);
        }
        static getDerivedStateFromProps(props, state) {
            const newBuildingId = props.building == undefined ? undefined : props.building.building_id;
            const newBuildingRevisionId = props.building == undefined ? undefined : props.building.revision_id;
            const categoryKeys = {};
            const blackListedKeys = ['current_landuse_order',
                'current_landuse_verified',
                'planning_list_grade',
                'likes_total',
                'community_type_worth_keeping_total',
                'community_local_significance_total',
                'community_expected_planning_application_total'
            ];
            for (let key in dataFields) {
                let fieldName = props.building == undefined ? undefined : props.building[key];
                if (dataFields[key].category == props.cat && fieldName != null && !blackListedKeys.includes(key)) {
                    categoryKeys[key] = true;
                }
                if (props.cat == 'team' && key == 'date_year' && fieldName != null && !blackListedKeys.includes(key)) {
                    categoryKeys[key] = true;
                }
            }
            if (newBuildingId !== state.currentBuildingId || newBuildingRevisionId > state.currentBuildingRevisionId) {
                return {
                    error: undefined,
                    copying: false,
                    keys_to_copy: categoryKeys,
                    buildingEdits: {},
                    currentBuildingId: newBuildingId,
                    currentBuildingRevisionId: newBuildingRevisionId,
                    mapColourScale: props.mapColourScale,
                    onMapColourScale: props.onMapColourScale
                };
            }
            return null;
        }
        /**
         * Enter or exit "copying" state - allow user to select attributes to copy
         */
        toggleCopying() {
            this.setState({
                copying: !this.state.copying
            });
        }
        /**
         * Keep track of data to copy (accumulate while in "copying" state)
         *
         * @param {string} key
         */
        toggleCopyAttribute(key) {
            const keys = Object.assign({}, this.state.keys_to_copy);
            if (this.state.keys_to_copy[key]) {
                delete keys[key];
            }
            else {
                keys[key] = true;
            }
            this.setState({
                keys_to_copy: keys
            });
        }
        isEdited() {
            // check if the edits object has any fields
            return !_.isEmpty(this.state.buildingEdits);
        }
        clearEdits() {
            this.setState({
                buildingEdits: {}
            });
        }
        getEditedBuilding() {
            if (this.isEdited()) {
                return Object.assign({}, this.props.building, this.state.buildingEdits);
            }
            else {
                return Object.assign({}, this.props.building);
            }
        }
        updateBuildingState(key, value) {
            const newBuilding = this.getEditedBuilding();
            newBuilding[key] = value;
            const [forwardPatch] = compareObjects(this.props.building, newBuilding);
            this.setState({
                buildingEdits: forwardPatch
            });
        }
        /**
         * Handle update directly
         * - e.g. as callback from MultiTextInput where we set a list of strings
         *
         * @param {String} name
         * @param {*} value
         */
        handleChange(name, value) {
            this.updateBuildingState(name, value);
        }
        handleReset() {
            this.clearEdits();
        }
        async doSubmit(edits) {
            this.setState({ error: undefined });
            try {
                const buildingUpdate = await sendBuildingUpdate(this.props.building.building_id, edits);
                const updatedBuilding = Object.assign({}, this.props.building, buildingUpdate);
                this.props.onBuildingUpdate(this.props.building.building_id, updatedBuilding);
            }
            catch (error) {
                this.setState({ error });
            }
        }
        async handleSubmit(event) {
            event.preventDefault();
            this.doSubmit(this.state.buildingEdits);
        }
        async handleSaveAdd(slug, newItem) {
            var _a;
            if (this.props.building[slug] != undefined && !Array.isArray(this.props.building[slug])) {
                this.setState({ error: 'Unexpected error' });
                console.error(`Trying to add a new item to a field (${slug}) which is not an array`);
                return;
            }
            if (this.isEdited()) {
                this.setState({ error: 'Cannot save a new record when there are unsaved edits to existing records' });
                return;
            }
            const edits = {
                [slug]: [...((_a = this.props.building[slug]) !== null && _a !== void 0 ? _a : []), newItem]
            };
            this.doSubmit(edits);
        }
        async handleSaveChange(slug, value) {
            if (this.isEdited()) {
                this.setState({ error: 'Cannot change this value when there are other unsaved edits. Save or discard the other edits first.' });
                return;
            }
            const edits = {
                [slug]: value
            };
            this.doSubmit(edits);
        }
        async handleVerify(slug, verify, x, y) {
            const verifyPatch = {};
            if (verify) {
                verifyPatch[slug] = this.props.building[slug];
            }
            else {
                verifyPatch[slug] = null;
            }
            try {
                const data = await apiPost(`/api/buildings/${this.props.building.building_id}/verify.json`, verifyPatch);
                if (data.error) {
                    this.setState({ error: data.error });
                }
                else {
                    if (verify) {
                        Confetti({
                            angle: 60,
                            disableForReducedMotion: true,
                            particleCount: 200,
                            ticks: 300,
                            origin: { x, y },
                            zIndex: 2000
                        });
                    }
                    this.props.onUserVerifiedUpdate(this.props.building.building_id, data);
                }
            }
            catch (err) {
                this.setState({ error: err });
            }
            if (slug == 'current_landuse_group') {
                const edits = {
                    'current_landuse_verified': true
                };
                this.doSubmit(edits);
            }
            console.log(slug + " verify button clicked");
        }
        render() {
            const currentBuilding = this.getEditedBuilding();
            const values_to_copy = {};
            for (const key of Object.keys(this.state.keys_to_copy)) {
                values_to_copy[key] = currentBuilding[key];
            }
            const data_string = JSON.stringify(values_to_copy);
            const copy = {
                copying: this.state.copying,
                toggleCopying: this.toggleCopying,
                toggleCopyAttribute: this.toggleCopyAttribute,
                copyingKey: (key) => this.state.keys_to_copy[key]
            };
            const headerBackLink = `/${this.props.mode}/categories${this.props.building != undefined ? `/${this.props.building.building_id}` : ''}`;
            const edited = this.isEdited();
            return (React.createElement("section", { id: this.props.cat, className: "data-section" },
                React.createElement(ContainerHeader, { cat: this.props.cat, title: this.props.title },
                    this.props.help && !copy.copying ?
                        React.createElement("a", { className: "icon-button help", title: "Find out more", href: this.props.help }, "Info")
                        : null,
                    this.props.building != undefined && !this.props.inactive ?
                        React.createElement(React.Fragment, null,
                            React.createElement(CopyControl, { cat: this.props.cat, data_string: data_string, copying: copy.copying, toggleCopying: copy.toggleCopying }),
                            !copy.copying ?
                                React.createElement(React.Fragment, null,
                                    React.createElement(NavLink, { className: "icon-button history", to: `/${this.props.mode}/${this.props.cat}/${this.props.building.building_id}/history` }, "Edit History"),
                                    React.createElement(ViewEditControl, { cat: this.props.cat, mode: this.props.mode, building: this.props.building }))
                                :
                                    null)
                        : null),
                React.createElement("div", { className: "section-body" },
                    React.createElement(InfoBox, null,
                        this.props.intro,
                        "\u00A0 Can you help us capture and verify this information?"),
                    React.createElement(EditHistoryLatest, { building: this.props.building }),
                    this.props.inactive ?
                        React.createElement(Fragment, null,
                            React.createElement(WrappedComponent, { intro: this.props.intro, building: this.props.building, mode: this.props.mode, edited: false, copy: copy, onChange: undefined, onVerify: undefined, onSaveAdd: undefined, onSaveChange: undefined, user_verified: [], mapColourScale: undefined, onMapColourScale: undefined })) :
                        this.props.building != undefined ?
                            React.createElement("form", { action: `/edit/${this.props.cat}/${this.props.building.building_id}`, method: "POST", onSubmit: this.handleSubmit },
                                React.createElement("button", { type: "submit", disabled: true, style: { display: 'none' } }),
                                (this.props.mode === 'edit' && !this.props.inactive) ?
                                    React.createElement("div", { className: 'edit-bar' },
                                        React.createElement(ErrorBox, { msg: this.state.error }),
                                        this.props.cat !== 'like' && // special-case for likes
                                            React.createElement("div", { className: "buttons-container with-space" },
                                                React.createElement("button", { type: "submit", className: "btn btn-primary", disabled: !edited, "aria-disabled": !edited }, "Save edits"),
                                                edited ?
                                                    React.createElement("button", { type: "button", className: "btn btn-warning", onClick: this.handleReset }, "Discard edits") :
                                                    null))
                                    : null,
                                React.createElement("hr", null),
                                React.createElement(WrappedComponent, { intro: this.props.intro, building: currentBuilding, mode: this.props.mode, edited: edited, copy: copy, onChange: this.handleChange, onVerify: this.handleVerify, onSaveAdd: this.handleSaveAdd, onSaveChange: this.handleSaveChange, user_verified: this.props.user_verified, user: this.props.user, mapColourScale: this.props.mapColourScale, onMapColourScale: this.props.onMapColourScale })) :
                            React.createElement(InfoBox, { msg: "Select a building to view data" }),
                    React.createElement("hr", null))));
        }
    };
};
export default withCopyEdit;
