import React, { Fragment } from 'react';
import '../../map/map-button.css';
import { Link } from 'react-router-dom';
import InfoBox from '../../components/info-box';
import { buildingUserFields, dataFields } from '../../config/data-fields-config';
import UserOpinionEntry from '../data-components/user-opinion-data-entry';
import { DataEntryGroup } from '../data-components/data-entry-group';
import SelectDataEntry from '../data-components/select-data-entry';
import withCopyEdit from '../data-container';
import PlanningDataOfficialDataEntry from '../data-components/planning-data-entry';
import { Category } from '../../config/categories-config';
import { useDisplayPreferences } from '../../displayPreferences-context';
const currentTimestamp = new Date().valueOf();
const milisecondsInYear = 1000 * 60 * 60 * 24 * 365;
const HeritageStatusOptions = [
    'Yes',
    'No'
];
// there is already "parseDate" in helpers
// but it is using timestamp as input, while this one
// uses lower accuracy (as actual data is using the same accuracy)
function parseDateSpecifiedWithDailyAccuracy(isoUtcDate) {
    const [year, month, day] = isoUtcDate.match(/^(\d{4})-(\d\d)-(\d\d)$/)
        .splice(1)
        .map(x => parseInt(x, 10));
    return new Date(Date.UTC(year, month - 1, day));
}
function isArchived(item) {
    const decisionDate = item.decision_date;
    if (decisionDate != null) {
        if ((currentTimestamp - parseDateSpecifiedWithDailyAccuracy(decisionDate).valueOf()) > milisecondsInYear) {
            return true;
        }
    }
    if (item.registered_with_local_authority_date != null) {
        if ((currentTimestamp - parseDateSpecifiedWithDailyAccuracy(item.registered_with_local_authority_date).valueOf()) > milisecondsInYear) {
            return true;
        }
    }
    return false;
}
const PlanningView = (props) => {
    const switchToExpectedApplicationMapStyle = (e) => {
        e.preventDefault();
        props.onMapColourScale('community_expected_planning_application_total');
    };
    const switchToBuildingProtectionMapStyle = (e) => {
        e.preventDefault();
        props.onMapColourScale('planning_combined');
    };
    const switchToAllPlanningApplicationsMapStyle = (e) => {
        e.preventDefault();
        props.onMapColourScale('planning_applications_status_all');
    };
    const { flood, floodSwitchOnClick, housing, housingSwitchOnClick, creative, creativeSwitchOnClick, vista, vistaSwitchOnClick, parcel, parcelSwitchOnClick, conservation, conservationSwitchOnClick, darkLightTheme } = useDisplayPreferences();
    const communityLinkUrl = `/${props.mode}/${Category.Community}/${props.building.building_id}`;
    return (React.createElement(Fragment, null,
        React.createElement(DataEntryGroup, { name: "Planning application information", collapsed: true },
            React.createElement(DataEntryGroup, { name: "Current/active applications (official data)" },
                React.createElement(InfoBox, null,
                    "This section provides data on active applications. We define these as applications with any activity in the last year.",
                    React.createElement("br", null),
                    "To comment on an application follow the application link if provided, or visit the relevant local authority's planning page."),
                props.building.planning_data ?
                    React.createElement(PlanningDataOfficialDataEntry, { shownData: props.building.planning_data.filter(item => isArchived(item) == false), messageOnMissingData: props.building.planning_data.length > 0 ?
                            "Only past application data is currently available for this site"
                            :
                                "No live planning data are currently available for this building." })
                    : React.createElement(React.Fragment, null)),
            React.createElement(DataEntryGroup, { name: "Past applications (official data)", collapsed: true },
                React.createElement(InfoBox, null, "This section provides data on past applications where available from the DPE, including those with no decision in over a year"),
                props.building.planning_data ?
                    React.createElement(PlanningDataOfficialDataEntry, { shownData: props.building.planning_data.filter(item => isArchived(item)), messageOnMissingData: props.building.planning_data.length > 0 ?
                            "Only current application data is currently available for this site"
                            :
                                "No live planning data are currently available for this building." })
                    : React.createElement(React.Fragment, null)),
            React.createElement(DataEntryGroup, { name: "Possible future applications (crowdsourced data)", collapsed: true },
                React.createElement(InfoBox, { type: 'info' },
                    "Click and colour buildings here if you think they may be subject to a future planning application involving demolition. To add your opinion on how well this building works, please also visit the ",
                    React.createElement(Link, { to: communityLinkUrl }, "Community"),
                    " section."),
                props.mapColourScale != "community_expected_planning_application_total" ?
                    React.createElement("button", { className: `map-switcher-inline disabled-state btn btn-outline btn-outline-dark ${darkLightTheme}`, onClick: switchToExpectedApplicationMapStyle }, 'Click here to view possible locations of future applications')
                    :
                        React.createElement("button", { className: `map-switcher-inline enabled-state btn btn-outline btn-outline-dark ${darkLightTheme}`, onClick: switchToAllPlanningApplicationsMapStyle }, 'Click to see planning applications'),
                React.createElement(UserOpinionEntry, { slug: 'community_expected_planning_application', title: buildingUserFields.community_expected_planning_application.title, userValue: props.building.community_expected_planning_application, onChange: props.onSaveChange, mode: props.mode, copy: props.copy }),
                React.createElement(InfoBox, { type: 'warning' }, "Further improvements to this feature are currently being made."))),
        React.createElement(DataEntryGroup, { name: "Forthcoming data (sections to be activated)", collapsed: true },
            React.createElement(DataEntryGroup, { name: "Active application info (crowdsourced)", collapsed: true },
                React.createElement(InfoBox, { type: 'warning' },
                    "This category is not yet activated -  Until this section is activated please report inaccuracies or problems on the ",
                    React.createElement("a", { href: " https://github.com/colouring-cities/colouring-london/discussions/categories/planning-section-comments" }, "Discussion Forum"),
                    "."),
                React.createElement("div", { className: "data-title" },
                    React.createElement("div", { className: "data-title-text" },
                        React.createElement("ul", null,
                            React.createElement("li", null, "Year of completion if known"),
                            React.createElement("li", null, "If you know of a planning application that has been recently submitted for this site, and is not listed in the blue box above, please enter its planning application ID below:"),
                            React.createElement("li", null, "If any of the active planning applications are not mapped onto the correct site, please tick here"))))),
            React.createElement(DataEntryGroup, { name: "Land ownership type", collapsed: true },
                React.createElement(InfoBox, { type: 'warning' }, "This category is not yet activated."),
                React.createElement(InfoBox, null, "This section is designed to provide information on land parcels and their ownership type. Can you help us to crowdsource this information?"),
                React.createElement("button", { className: `map-switcher-inline ${parcel}-state btn btn-outline btn-outline-dark ${darkLightTheme}`, onClick: parcelSwitchOnClick }, (parcel === 'enabled') ? 'Click to hide sample of parcel data (in City)' : 'Click to see sample of parcel data (in City) mapped'),
                React.createElement("div", { className: "data-title" },
                    React.createElement("div", { className: "data-title-text" },
                        React.createElement("ul", null,
                            React.createElement("li", null, "What type of owner owns this land parcel?")))))),
        React.createElement(DataEntryGroup, { name: "Heritage", collapsed: true },
            React.createElement(SelectDataEntry, { title: dataFields.ext_in_heritage.title, slug: "ext_in_heritage", value: props.building.ext_in_heritage, tooltip: dataFields.ext_in_heritage.tooltip, options: HeritageStatusOptions, mode: props.mode, copy: props.copy, onChange: props.onChange }))));
};
const PlanningContainer = withCopyEdit(PlanningView);
export default PlanningContainer;
